<template>
    <div>
        <h1>B-Wurf</h1>
        <h3 style="margin-bottom: 64px">14.01.2023</h3>

        <div class="image">
             <img src="../../assets/img/B-Wurf/B-Wurf.jpg" alt="B-Wurf">
        </div>

        <DogParents :mom="mom" :dad="dad"/>

        <div class="puppies">
            <div class="puppy">
                <h2>Branka</h2>
                <h4>geb. 14.01.2023 4:03 Uhr, 430g, Kennfarbe gelb, Hündin</h4>
                <ImageCard :images="gelb"/>
            </div>
            <div class="puppy">
                <h2>Baghira</h2>
                <h4>geb. 14.01.2023 4:50 Uhr, 395g, Kennfarbe blau, Rüde</h4>
                <ImageCard :images="blau"/>
            </div>
            <div class="puppy">
                <h2>Bente</h2>
                <h4>geb. 14.01.2023 5:51 Uhr, 455g, Kennfarbe rot, Hündin</h4>
                <ImageCard :images="bente"/>
            </div>
            <div class="puppy">
                <h2>Bruno</h2>
                <h4>geb. 14.01.2023 6:49 Uhr, 405g, Kennfarbe grün, Rüde</h4>
                <ImageCard :images="green"/>
            </div>
            <div class="puppy">
                <h2>B-Winry</h2>
                <h4>geb. 14.01.2023 7:45 Uhr, Kennfarbe rosa, Hündin</h4>
                <ImageCard :images="rosa"/>
            </div>
        </div>
    </div>
</template>

<script>
import ImageCard from '../../components/ImageCard.vue';
import DogParents from '../../components/DogParents.vue';

export default {
  name: 'App',
  data() {
    return {
        mom: {
            name: "Khaleesi von den Wolfswiesen",
            bday: "geb. 23.04.2018",
            img_face: require("../../assets/img/Khali_Face_small.jpg"),
            img_side: require("../../assets/img/Khali_Side.jpg")
        },
        dad: {
            name: "Harley vom Cluesberg",
            bday: "geb. 17.03.2016",
            img_face: require("../../assets/img/B-Wurf/Harley_2.jpg"),
            img_side: require("../../assets/img/B-Wurf/Harley_1.jpg")
        },
        gelb: {
            woche1: require("../../assets/img/B-Wurf/Woche1/gelb.jpg"),
            abgabe: require("../../assets/img/B-Wurf/Abgabe/Branka.jpg"),
            halbesJahr: require("../../assets/img/B-Wurf/HalbesJahr/Branka.jpg"),
            jahr: require("../../assets/img/B-Wurf/Jahr/Branka.jpg"),
        },
        blau: {
            woche1: require("../../assets/img/B-Wurf/Woche1/blau.jpg"),
            abgabe: require("../../assets/img/B-Wurf/Abgabe/Baghira.jpg"),
            halbesJahr: require("../../assets/img/B-Wurf/HalbesJahr/Baghira.jpg"),
            jahr: require("../../assets/img/B-Wurf/Jahr/Baghira.jpg"),
        },
        bente: {
            woche1: require("../../assets/img/B-Wurf/Woche1/rot.jpg"),
            abgabe: require("../../assets/img/B-Wurf/Abgabe/Bente.jpg"),
            halbesJahr: require("../../assets/img/B-Wurf/HalbesJahr/Bente.jpg"),
            jahr: require("../../assets/img/B-Wurf/Jahr/Bente.jpg"),
        },
        green: {
            woche1: require("../../assets/img/B-Wurf/Woche1/green.jpg"),
            abgabe: require("../../assets/img/B-Wurf/Abgabe/Bruno.jpg"),
            halbesJahr: require("../../assets/img/B-Wurf/HalbesJahr/Bruno.jpg"),
            jahr: require("../../assets/img/B-Wurf/Jahr/Bruno.jpg"),
        },
        rosa: {
            woche1: require("../../assets/img/B-Wurf/Woche1/rosa.jpg"),
            abgabe: require("../../assets/img/B-Wurf/Abgabe/Winry.jpg"),
            halbesJahr: require("../../assets/img/B-Wurf/HalbesJahr/Winry.jpg"),
            jahr: require("../../assets/img/B-Wurf/Jahr/Winry.jpg"),
        },
    }
  },
  components: {
    ImageCard,
    DogParents
    },
    props: ["onZuchtstaette"],
    emits: ["update-zuchtstaette-nav-on", "update-zuchtstaette-nav-off"],
    mounted() {
        this.$emit("update-zuchtstaette-nav-on")
    },
    unmounted() {
        this.$emit("update-zuchtstaette-nav-off")
    }
}
</script>

<style scoped>
:root {
  --backgroundColor: #dcdece;
  --mainText: #333D29;
  --mainTextLight: #70875A;
  --linkText: #936639;
  --focusedText: #582F0E;
  --higlightedText: #04A0A2;
}

h1 {
    margin-bottom: 0px;
}

h3 {
    color: var(--mainTextLight);
}

h4 {
    color: var(--mainTextLight);
    margin-bottom: 16px;
}

h2 {
    margin-bottom: 0px;
}

.image {
    display: grid;
    justify-content: center;
    align-items: center;
}

img {
    max-width: 750px;
    width: 100%;
    height: auto;
    margin-bottom: 32px;
    border-radius: 8px;
}

.puppies {
    display: grid;
    gap: 64px 0px;
}

h4 {
    display: flex;
}

i {
    color: var(--mainTextLight);
    font-size: 0.8em;
}
</style>